










import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepProjectionVideoViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-projection-video-view-model';

@Component({
  name: 'FlagshipProfilingStepProjectionVideo',
  components: {
    FlagshipProfilingVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingVideo.vue'),
  },
})
export default class FlagshipProfilingStepProjectionVideo extends Vue {
  flagship_projection_video_view_model = Vue.observable(
    new FlagshipProfilingStepProjectionVideoViewModel(this),
  );
}
